.coupon-carousel::-webkit-scrollbar {
  display: none;
}

.coupon-carousel {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.coupon-carousel__coupon {
  box-shadow: none;
  height: 100% !important;
}

.coupon-carousel__coupon div:nth-child(1) {
  justify-content: space-between;
}
