.input-label__active input {
  border-color: var(--color-neutral-black);
  border-width: 0.5px;
}

.input-label__focus input {
  border-color: var(--color-neutral-black);
  border-width: 0.5px;
}

.input-label__error input {
  border-color: var(--color-feedback-error);
  border-width: 0.5px;
}

.input-label input {
  width: -moz-available;
}

.input-label span {
  bottom: 160% !important;
  margin-left: -160px !important;
}

.input-label span::after {
  right: 12% !important;
}

.input-label input:-webkit-autofill,
.input-label input:-webkit-autofill:hover,
.input-label input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.sign-up-label__modal {
  padding: 1.25rem 0;
}
