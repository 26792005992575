.description {
    transition: max-height 0.5s ease-in-out;
    max-height: 58px !important;
    overflow: hidden;
}

.description__open {
    max-height: 500px !important;
}

.description__close {
    max-height: 100px !important;
}